/**
 * @author: Elena Giacomazzi, Leon Fruth, Franziska Mäckel, Eva Jansohn
 * @date: 2021-03-30
 */

 .clickableNumber {
  border: 0vw solid /*var(--primary-lila)*/;
  height: 3vw;
  width: 3vw;
  font-size: 2vw;
  background-color: white;
  color: rgb(174, 174, 174);
  
}


.clickableNumber:hover {
  background-color: var(--button-grey);
  color: black;
}


.X {
  position: relative;
  color: rgb(78, 78, 78);
  top: 0vw;
  left: 2.95vw;
  font-family: Arial, Helvetica, sans-serif;
  display: block;
  border-radius: 50%;
  
  font-size: 1vw;
  
  border: none;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica, Arial, sans-serif;

  
}

.analogy .clickableNumber {
  border: 0vw solid var(--primary-lila);
  height: 2vw;
  width: 2vw;
  
}

.analogy .X {
  position: relative;
  color: var(--primary-lila);
  top: 0vw;
  left: 1.5vw;
  font-family: Arial, Helvetica, sans-serif;
 
  opacity: 0.8;
}
