/**
 * @author: Elena Giacomazzi, Leon Fruth, Franziska Mäckel
 * @date: 2021-03-30
 */

body,
html {
  height: 100%;
  margin: 0;
}

#root {
  height: 100%;
}
