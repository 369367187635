/**
 * @author: Elena Giacomazzi, Leon Fruth, Franziska Mäckel, Eva Jansohn
 * @date: 2021-03-30
 */

.ownEx {
  display: grid;
  grid-template-areas:
    'bubble addigator'
    'bubble addigator';
  grid-template-columns: 2fr 1fr;
  padding-top: 4vw;
}

.ownEx > .Addigator {
  grid-area: addigator;
  margin: auto;
}

.ownEx > .Addigator > img {
  height: 30vw;
}
