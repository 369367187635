/**
 * @author: Elena Giacomazzi, Leon Fruth, Franziska Mäckel
 * @date: 2021-03-30
 */

.roundNumber {
  display: inline;
  height: 6vw;
  width: 6vw;
  border-radius: 50%;
  background-color: var(--button-grey);
  font-size: 3vw;
  text-align: center;
  border: none;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica, Arial, sans-serif;
}

.analogy .roundNumber {
  display: inline;
  height: 3vw;
  width: 3vw;
  border-radius: 50%;
  background-color: var(--button-grey);
  font-size: 1.15vw;
  text-align: center;
  border: none;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica, Arial, sans-serif;
}
