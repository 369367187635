/**
 * @author: Elena Giacomazzi, Leon Fruth, Franziska Mäckel
 * @date: 2021-03-30
 */

.buttonClose {
  background-color: transparent;
  border: none !important;
  width: 4.5vw;
  height: 4.5vw;
}

.CloseSpeech {
  z-index: 2;
  position: relative;
  left: -4.5vw;
  grid-area: close;
}

@media only screen and (max-width: 600px) {
  .CloseSpeech {
    left: -6vw;
  }
  .buttonClose {
    width: 6vw;
    height: 6vw;
  }
}
