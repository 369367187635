/**
 * @author: Elena Giacomazzi, Leon Fruth, Franziska Mäckel
 * @date: 2021-03-30
 */

.Speech {
  grid-area: speech;
}

.right {
  grid-area: rightway;
}

.left {
  grid-area: leftway;
}

.SpeechbubbleControls {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-areas:
    'speech speech speech'
    'leftway . rightway';
  grid-template-columns: 2fr 1fr 2fr;
  grid-template-rows: 3fr 1fr;
}
