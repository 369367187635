/**
 * @author: Elena Giacomazzi, Leon Fruth, Franziska Mäckel, Eva Jansohn
 * @date: 2021-03-30
 */

.inputOwnEx {
  position: relative;
  width: 70%;
  grid-area: bubble;
  margin: auto;
  display: grid;
  grid-template-areas: 'inputExercise close';
}

.inputOwnExBubble {
  font-family: var(--font-family);
  position: relative;
  grid-area: inputExercise;
  padding: 2vw 3vw 0 3vw;
  width: 45vw;
  height: 42vw;
  border-radius: 12px;
  border: 0.5vw solid #eee;
  background-color: white;
  display: grid;
  align-items: center;
  grid-template-areas:
    'textOwnEx textOwnEx'
    'obereZahl obereInput'
    'untereZahl untereInput'
    'errorText errorText'
    'losButton losButton';
  grid-template-rows: 12vw 7vw 7vw 7vw 7vw;
}

.inputOwnExBubble:before,
.inputOwnExBubble:after {
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  content: '\0020';
  overflow: hidden;
  z-index: 2;
  left: 50.5vw;
  bottom: 15vw;
  border: solid 3vw transparent;
  border-left-color: #fff;
}
.inputOwnExBubble:before {
  z-index: 1;
  left: 51vw;
  border-top: 0;
  border: solid 3vw transparent;
  border-left-color: #eee;
}

.ownExText {
  grid-area: textOwnEx;
  text-align: justify;
  font-size: 2.5vw;
}
.obereZahlText {
  grid-area: obereZahl;
  text-align: right;
  font-size: 2.5vw;
}
.obereZahlInput {
  grid-area: obereInput;
  text-align: right;
}
.untereZahlText {
  grid-area: untereZahl;
  text-align: right;
  font-size: 2.5vw;
}
.untereZahlInput {
  grid-area: untereInput;
  text-align: right;
}

.errorText {
  grid-area: errorText;
  text-align: center;
  font-size: 2vw;
  color: var(--error-pink);
}

.inputOwnExBubble p {
  position: relative;
  z-index: 2;
}

.inputOwnExBubble p:last-child {
  margin-bottom: 0;
}

.obereZahlInput,
.untereZahlInput {
  display: block;
  margin: 0vw auto;
  border: none;
  padding: 0;
  width: 6.5ch;
 /* background: repeating-linear-gradient(
      90deg,
      black 0,
      black 0.99ch,
      transparent 0,
      transparent 1.2ch
    )
    0 100%/ 5ch 2px no-repeat;*/
    border-bottom: 2.5px solid black;
  font: 2.5vw droid sans mono, consolas, monospace;
  letter-spacing: 0.2ch;
}
.obereZahlInput:focus,
.untereZahlInput:focus {
  outline: none;
  color: var(--light-blue);
}

.eigeneAufgabeSubmit {
  background-color: var(--primary-lila);
  color: var(--light-blue);
  border: none;
  text-decoration: none;
  border-radius: 4%/12%;
  font-size: 4vw;
  width: 15vw;
  grid-area: losButton;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
