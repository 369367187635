/**
 * @author: Elena Giacomazzi, Leon Fruth, Franziska Mäckel
 * @date: 2021-03-30
 */

.left,
.right {
  background-color: transparent;
  border: rgb(15, 22, 219);
  cursor: pointer;
}