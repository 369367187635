/**
 * @author: Elena Giacomazzi, Leon Fruth, Franziska Mäckel, Eva Jansohn
 * @date: 2021-03-30
 */
 
.welcome {
  display: grid;
  grid-template-areas:
    'speechPart .'
    'speechPart addigator'
    'speechPart .';
  grid-template-columns: 2fr 1fr;
  padding: 8vw 0 0 0;
}

.welcome > .SpeechbubbleControls {
  grid-area: speechPart;
}

.welcome > .Addigator {
  grid-area: addigator;
  margin: auto;
}

.welcome > .Addigator > img {
  height: 30vw;
}

.welcome .right,
.welcome .left {
  width: 28vw;
}

.welcome .bubbleText {
  font-size: 3.3vw;
}

.welcome .bubble {
  position: relative;
  padding: 5.5vw 3vw 1vw 3vw;
  width: 35vw;
  height: 15vw;
  border-radius: 12px;
  border: 0.5vw solid #eee;
  background-color: white;
  font-size: 4vw;
  font-family: var(--font-family);
  text-align: center;
}

.welcome .bubble:before,
.welcome .bubble:after {
  left: 40.5vw;
  bottom: 2vw;
  border: solid 3vw transparent;
  border-left-color: #fff;
}

.welcome .bubble::before {
  left: 41vw;
  border-top: 0;
  border: solid 3vw transparent;
  border-left-color: #eee;
}

.welcome .testimonial {
  margin: 0 10vw;
}

@media only screen and (max-width: 600px) {
  .welcome .bubbleText {
    font-size: 3vw;
  }

  .welcome .bubble {
    padding-top: 5vw;
    padding-left: 1vw;
    padding-right: 1vw;
  }

  .welcome .bubble:before,
  .welcome .bubble:after {
    left: 36.5vw;
  }

  .welcome .bubble::before {
    left: 37vw;
  }
}
