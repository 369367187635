/**
 * @author: Elena Giacomazzi, Leon Fruth, Franziska Mäckel
 * @date: 2021-03-30
 */

.App {
  height: 100%;
}

.SubHallo {
  color: white;
  font-size: 3vh;
  margin: 0;
}

html {
  background: url('../resources/sumpf.png') no-repeat center center
    fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  
}
