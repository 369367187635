/**
 * @author: Elena Giacomazzi, Leon Fruth, Franziska Mäckel
 * @date: 2021-03-30
 */

.testimonial {
  position: relative;
  width: 70%;
  display: grid;
  grid-template-areas: 'bubble close';
}

.bubble p {
  position: relative;
  z-index: 2;
}
.bubble p:last-child {
  margin-bottom: 0;
}

.bubble:before,
.bubble:after {
  display: block;
  position: absolute;
  right: 4em;
  bottom: -18px;
  width: 0;
  height: 0;
  content: '\0020';
  border: solid 20px transparent;
  border-bottom: 0;
  border-top-color: #fff;
  overflow: hidden;
  z-index: 2;
}
.bubble:before {
  bottom: -24px;
  border-top-color: #eee;
  z-index: 1;
}
