/**
 * @author: Elena Giacomazzi, Leon Fruth, Franziska Mäckel
 * @date: 2021-03-30
 */
 .impressumLink {
  background-color: var(--primary-lila);
  color: var(--light-blue);
  font-size: 0.7vw;
  border-style: none;
  width: 6vw;
  height: 1.2vw;
  border-radius: 10px;
  bottom: 10px;
  left: 5px;
}
  