/**
 * @author: Elena Giacomazzi, Leon Fruth, Franziska Mäckel, Eva Jansohn
 * @date: 2021-03-30
 */

.panel {
  grid-area: panel;
  margin-left: 2vw;
}

.analogy {
  grid-area: analogy;
}

.calculate {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-areas: 'buttons addigator_small' 'panel analogy';
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 8vw auto;
}

.calculate .Speech {
  width: 100%;
}

.calculate .testimonial {
  padding: 0 1.5vw 0 3.5vw;
  width: 40vw;
}

.calculate .SpeechbubbleControls {
  grid-template-rows: 35vw 8vw;
}

.calculate .left,
.calculate .right {
  align-self: end;
}

.calculate > .Addigator {
  grid-area: analogy;
  margin: auto;
}

.calculate > .Addigator > img {
  height: 30vw;
}

.Addigator_small {
  grid-area: addigator_small;
  text-align: center;
}

.Addigator_small > img {
  height: 5vw;
  margin-top: 1vw;
}

.buttonContainer {
  width: auto;
  height: 8vw;
  grid-area: buttons;
  display: grid;
  grid-template-areas: 'ownButton randomButton ';
  align-items: center;
  margin-left: 6vw;
  
}
.dropdown {
  position: relative;
  display: inline-block;
  font-size: 2vw;
}

.randomExerciseButton {
  background-color: var(--primary-lila);
  color: var(--light-blue);
  font-size: 2vw;
  border-style: none;
  width: 20vw;
  height: 5vw;
  font-weight: bold;
  border-radius: 10px;
} 
.levels {
  display: none;
  position: absolute;
  background-color:  var(--light-blue);
  min-width: 20vw;
  height: 5vw;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.068);
  z-index: 1;
}

.levels li {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  list-style: none;
  background-color:  var(--light-blue);
  font-family: var(--font-family);
  font-weight: bold;
  border: 1px solid var(--primary-lila);
  border-radius: 10px;
  
text-align: center;

}

.levels li a {
  text-decoration: none;
  color: var(--primary-lila);
  display: block;
  padding: 10px;
  
}

.levels li:hover {
  background-color: #0979ad;
  color: white;
}


.ownExerciseButton {
  background-color: var(--primary-lila);
  color: var(--light-blue);
  font-size: 2vw;
  border-style: none;
  width: 20vw;
  height: 5vw;
  font-weight: bold;
  border-radius: 10px;
}

.calculate .bubble {
  position: relative;
  padding: 2vw 3vw 1vw 3vw;
  width: 32vw;
  border-radius: 12px;
  border: 0.5vw solid #eee;
  background-color: white;
  font-size: 2vw;
  font-family: var(--font-family);
  text-align: justify;
}

.analogy .bubble:before,
.analogy .bubble:after {
  right: 17vw;
  top: -1vw;
  border: solid 2vw transparent;
  border-top: 0;
  border-bottom-color: #fff;
  z-index: 0;
}

.analogy .bubble::before {
  right: 17vw;
  top: -3.8vw;
  border: solid 2vw transparent;
  border-bottom-color: #eee;
  z-index: -1;
}

.analogy .panel {
  padding: 0.5vw 0 0 0;
}

@media only screen and (max-width: 600px) {
  .calculate .bubble {
    padding-top: 4vw;
  }
}




