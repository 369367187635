/**
 * @author: Elena Giacomazzi, Leon Fruth, Franziska Mäckel, Eva Jansohn
 * @date: 2021-03-30
 */
 
 .panel {
  padding: 1vw;
  width: 50vw;
  height: 38vw;
  border-radius: 5%;
  background-color: rgba(255, 255, 255, 0.7);
}

.panel > .grid-container {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: auto auto 0fr auto;
  grid-column-gap: 0.5vw;
  grid-row-gap: 1.5vw;
  grid-template-areas:
    
    '. . topA topB topC topD topE . .'
    'plus . bottomA bottomB bottomC bottomD bottomE . .'
    '. correction0 correction1 correction2 correction3 correction4 correction5 . .'
    'line line line line line line line line line '
    '. resultA resultB resultC resultD resultE resultF check refresh'
    'error error error error error error error error error';
}

.line {
  border-top: 0.5vw solid var(--primary-lila);
  grid-area: line;
}

.plus {
  font-size: 5vw;
  text-align: center;
  grid-area: plus;
}

.panelControls {
  background-color: var(--primary-lila);
  border: none;
  border-radius: 10%;
}

.correction0 {
  grid-area: correction0;
}

.correction1 {
  grid-area: correction1;
}

.correction2 {
  grid-area: correction2;
}

.correction3 {
  grid-area: correction3;
}

.correction4 {
  grid-area: correction4;
}

.correction5 {
  grid-area: correction5;
}

.bottom0 {
  grid-area: bottomA;
}

.bottom1 {
  grid-area: bottomB;
}
.bottom2 {
  grid-area: bottomC;
}
.bottom3 {
  grid-area: bottomD;
}
.bottom4 {
  grid-area: bottomE;
}


.top0 {
  grid-area: topA;
  width: 6vw;
  height: 6vw;
}
.top1 {
  grid-area: topB;
  width: 6vw;
  height: 6vw;
}
.top2 {
  grid-area: topC;
  width: 6vw;
  height: 6vw;
}
.top3 {
  grid-area: topD;
  width: 6vw;
  height: 6vw;
}
.top4 {
  grid-area: topE;
  width: 6vw;
  height: 6vw;
}
.top5 {
  grid-area: topF;
  width: 6vw;
  height: 6vw;
}

.result0 {
  grid-area: resultA;
}
.result1 {
  grid-area: resultB;
}
.result2 {
  grid-area: resultC;
}
.result3 {
  grid-area: resultD;
}
.result4 {
  grid-area: resultE;
}
.result5 {
  grid-area: resultF;
}
.check {
  grid-area: check;
}
.refresh {
  grid-area: refresh;
}
.errorMessage {
  grid-area: error;
  font-family: Arial, Helvetica, sans-serif;
  color: var(--error-pink);
  font-size: 1.5vw;
  margin-top: 2vw;
}

.highlighted {
  background: var(--highlight) !important;
  color:black !important;
}

.analogy .panel {
  width: 25vw;
  height: 18vw;
  border-radius: 5%;
  background-color: rgba(255, 255, 255, 0.7);
  align-content: center;
}
/* add fourth column */
.analogy .panel > .grid-container {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: auto auto 0fr auto;
  grid-column-gap: 0.25vw;
  grid-row-gap: 0.9vw;
  grid-template-areas:    
  '. . topA topB topC topD topE topF .'
  'plus . bottomA bottomB bottomC bottomD bottomE bottomF .'
  '. . correction0 correction1 correction2 correction3 correction4 correction5  .'
  'line line line line line line line line line '
  '. . resultA resultB resultC resultD resultE resultF refresh'
  'error error error error error error error error error';
}
.analogy .line {
  border-top: 0.25vw solid var(--primary-lila);
  grid-area: line;
}

.analogy .plus {
  font-size: 2.5vw;
  text-align: center;
  grid-area: plus;
}

.analogy .panelControls {
  background-color: var(--primary-lila);
  border: none;
  border-radius: 10%;
}

.analogy .correction0 {
  grid-area: correction0;
}

.analogy .correction1 {
  grid-area: correction1;
}

.analogy .correction2 {
  grid-area: correction2;
}
.analogy .correction3 {
  grid-area: correction3;
}
.analogy .correction4 {
  grid-area: correction4;
}
.analogy .correction5 {
  grid-area: correction5;
}

.analogy .bottom0 {
  grid-area: bottomA;
}

.analogy .bottom1 {
  grid-area: bottomB;
}
.analogy .bottom2 {
  grid-area: bottomC;
}
.analogy .bottom3 {
  grid-area: bottomD;
}
.analogy .bottom4 {
  grid-area: bottomE;
}
.analogy .bottom5 {
  grid-area: bottomF;
}

.analogy .top0 {
  grid-area: topA;
  width: 3vw;
  height: 1vw;
}
.analogy .top1 {
  grid-area: topB;
  width: 3vw;
  height: 3vw;
}
.analogy .top2 {
  grid-area: topC;
  width: 3vw;
  height: 3vw;
}
.analogy .top3 {
  grid-area: topD;
  width: 3vw;
  height: 3vw;
}.analogy .top4 {
  grid-area: topE;
  width: 3vw;
  height: 3vw;
}
.analogy .top5 {
  grid-area: topF;
  width: 3vw;
  height: 3vw;
}
.analogy .result0 {
  grid-area: resultA;
}
.analogy .result1 {
  grid-area: resultB;
}
.analogy .result2 {
  grid-area: resultC;
}
.analogy .result3 {
  grid-area: resultD;
}
.analogy .result4 {
  grid-area: resultE;
}
.analogy .result5 {
  grid-area: resultF;
}
